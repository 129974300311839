<template>
  <div class="list">
    <div class="warn-input">
      <el-row :gutter="23">
        <el-col :span="17">
          <el-date-picker
              v-model="value_dateTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="搜索" v-model="value">
            <el-button type="primary" slot="append" icon="el-icon-search" @click="searchAlarm"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="warn-list">
      <div class="warn-short" v-for="item in warn_data_list" :key="item.pk" @click="sendInformations(item)">
        <el-card shadow="hover" :class="item.show ? 'active':''">
          <el-row :gutter="20">
            <el-col :span="14">
              <i class="el-icon-message-solid"></i> <span>{{ item.fields.warnningType }}</span>
            </el-col>
            <el-col :span="10">
              <el-button style="float: right;margin-right: 10px" type="text">{{ item.fields.datetime }}
              </el-button>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="14">
              <div class="grid-content bg-purple">{{ item.fields.address }}，发出{{
                  item.fields.warnningType
                }}报警。
              </div>
            </el-col>
            <el-col :span="10">
              <el-row style="float: right">
                <div v-show="operatorRes">

                  <div style="float: right" v-show="flag_handle">
                    <el-button v-if="item.fields.handled==0" size="mini" round
                               @click="handledAlarm(item)"><i class="el-icon-message-solid"></i> 解除
                    </el-button>
                    <el-button v-else size="mini" round type="primary"><i
                        class="el-icon-message-solid"></i> 已解除
                    </el-button>
                  </div>

                  <!--                                    <div style="float: right" v-show="!flag_handle" >-->
                  <!--                                        <el-button style="float: right" type="info" size="mini">暂无权权限</el-button>-->
                  <!--                                      </div>-->

                  <div style="float: right" v-show="flag_delete">
                    <el-button size="mini" round @click="deleteAlarm(item)"><i
                        class="el-icon-close"></i> 删除
                    </el-button>
                  </div>

                  <!--                                    <div style="float: right" v-show="!flag_delete" >-->
                  <!--                                        <el-button style="float: right" type="info" size="mini">暂无权</el-button>-->
                  <!--                                      </div>-->
                </div>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <div class="warn-pagination">
      <el-pagination
          small
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="paginationNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {handled, queryAlarmAll, delAlarm} from '../../api/requests.js'
import {EventBus} from "../store/bus.js";

export default {
  name: "WarnEventList",
  data() {
    return {
      value: '',
      value_dateTime: null,
      cardStyle: true,
      operatorRes: true,
      flag_delete: false,
      flag_handle: false,
      currentPage: 1,
      paginationNum: 10,
      warn_data: [],
      warn_data_cache: [],
      warn_data_list: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.warn_data_list = this.warn_data.slice((val - 1) * 10, val * 10)
      this.sendInformations(this.warn_data_list[0])
    },
    searchAlarm() {
      if (this.value == '' && this.value_dateTime == null) {
        let cache = this.warn_data_cache
        this.warn_data = cache
        this.warn_data_list = cache.slice(0, 10)
        this.paginationNum = Object.keys(cache).length
        this.sendInformations(this.warn_data_list[0])
      } else {
        let warnData = this.warn_data
        let searchData = []
        warnData.forEach(res => {
          if (JSON.stringify(res.fields).match(this.value) != null) {
            searchData.push(res)
          }
        })

        this.warn_data_list = searchData.slice(0, 10)
        this.warn_data = searchData
        this.currentPage = 1
        this.paginationNum = Object.keys(searchData).length
        this.sendInformations(this.warn_data_list[0])
      }

    },
    getTimeNow() {
      let myDate = new Date();    //创建Date对象
      let Y = myDate.getFullYear();   //获取当前完整年份
      let M = myDate.getMonth() + 1;  //获取当前月份
      let D = myDate.getDate();   //获取当前日1-31
      let H = myDate.getHours();  //获取当前小时
      let i = myDate.getMinutes();    //获取当前分钟
      let s = myDate.getSeconds();    //获取当前秒数
      if (M < 10) {
        M = '0' + M;
      }
      // 日不足10补0
      if (D < 10) {
        D = '0' + D;
      }
      // 小时不足10补0
      if (H < 10) {
        H = '0' + H;
      }
      // 分钟不足10补0
      if (i < 10) {
        i = '0' + i;
      }
      // 秒数不足10补0
      if (s < 10) {
        s = '0' + s;
      }
      return Y + '-' + M + '-' + D + ' ' + H + ':' + i + ':' + s;
    },
    handledAlarm(item) {
      this.$confirm('确定要解除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        let user = localStorage.getItem('Authorization')
        this.warn_data_list.forEach(res => {
          if (res === item) {
            res.fields.handled = '1' // 已处理
            res.fields.handledTime = this.getTimeNow()
          }
        })
        let param = {'info': user, 'alarm': JSON.stringify(item)}
        handled(param).then(res => {
          console.log(res)
        })
      }).catch(() => {
      });
    },
    deleteAlarm(item) {
      console.log('item=============', item)
      // 二次确认删除
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        let user = localStorage.getItem('Authorization')
        let param = {'info': user, 'alarm': JSON.stringify(item)}
        let warn_data = this.warn_data
        warn_data = warn_data.filter(function (res) {
          return res != item
        });
        delAlarm(param).then(res => {
          if (res.data.code == 200) {
            this.warn_data = warn_data
            let l_start = (this.currentPage - 1) * 10
            let l_end = this.currentPage * 10
            this.warn_data_list = warn_data.slice(l_start, l_end)
            this.sendInformations(this.warn_data_list[0])
          }
        })
      }).catch(() => {
      });


    },
    sendInformations(item) {
      // 改变选中
      this.warn_data_list.forEach(res => res['show'] = false)
      item.show = !item.show
      EventBus.$emit('Informations', item)
    },
    getAlarmData() {
      console.log('==================================')
      let url = window.location.href;
      let ind = window.location.href.indexOf('factory')
      console.log(ind)
      console.log('url==========', url)
      let param = this.$route.query // url参数
      let user = localStorage.getItem('Authorization')
      param['info'] = user

      if (this.$route.path === '/eventcenter' && ind === -1) {
        let item = localStorage.getItem('item')
        console.log('1111111111111111111111111')
        console.log(item)
        param['factory'] = item
      }
      queryAlarmAll(param).then(res => {
        let factoryTypeDefault = {}
        res.data.factory.forEach(res => {
          factoryTypeDefault[res.pk] = res.fields.factoryName
        })
        res.data.info.forEach(res => {
          res['show'] = false
          res['factory'] = factoryTypeDefault[res.fields.factory]
          let date = new Date(res.fields.datetime);
          res.fields.datetime = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
          res.fields.createTime = null
          if (res.fields.handledTime !== null) {
            let date_Handled = new Date(res.fields.handledTime);
            res.fields.handledTime = this.$moment(date_Handled).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        this.warn_data = res.data.info
        this.warn_data_cache = res.data.info

        let l_start = (this.currentPage - 1) * 10
        let l_end = this.currentPage * 10
        this.warn_data_list = res.data.info.slice(l_start, l_end)

        this.paginationNum = Object.keys(res.data.info).length
        console.log('res.data.operator======', res.data.operator)
        // this.operatorRes = res.data.operator
        if (res.data.info.length <= 0) {
          this.sendInformations({'fields': ''})
          return
        }
        this.sendInformations(this.warn_data_list[0])
      })
    },
  },
  created() {
    console.log('this.$route.path====', this.$route.path)
    let flag_data = JSON.parse(localStorage.getItem('Authorization'))
    console.log('flag_data.group==========', flag_data.group.authority)
    if (flag_data.group.authority.indexOf('handle') !== -1) {
      this.flag_handle = true
    }
    if (flag_data.group.authority.indexOf('delete') !== -1) {
      this.flag_delete = true
    }
  },
  mounted() {
    this.getAlarmData()
  },
  watch: {
    $route() {
      //监听router变化 选中aside-item
      this.getAlarmData()
    },
    value_dateTime: {
      handler() {
        console.log(this.value_dateTime)
        if (!this.value_dateTime) {
          let cache = this.warn_data_cache
          this.warn_data = cache

          this.warn_data_list = cache.slice(0, 10)
          this.currentPage = 1
          this.paginationNum = Object.keys(cache).length
          this.sendInformations(this.warn_data_list[0])
        } else {
          let w_data = []
          this.warn_data_cache.forEach(res => {
            let w_dataTime = Number.parseInt(new Date(res.fields.datetime).getTime())
            if (this.value_dateTime[0] <= w_dataTime && w_dataTime <= this.value_dateTime[1] + 3600 * 1000 * 24) {
              w_data.push(res)
            }
          })
          this.warn_data = w_data
          this.warn_data_list = w_data.slice(0, 10)
          this.currentPage = 1
          this.paginationNum = Object.keys(w_data).length
        }
      },
    }
  }
}
</script>

<style scoped>

.warn-list {
  margin-top: 15px;
  height: 70vh;
  overflow: auto;
}

.warn-pagination {
  margin-top: 15px;
  margin-bottom: 15px;
  float: right;
}

.active {
  color: #409EFF;
  background-color: #F5F7FA;
}

.list {
  height: 80vh;
}
</style>